export default [
  {
    path: '/settings/account',
    name: 'app-settings-account',
    component: () => import('@/views/pages/account-setting/AccountSetting.vue'),
    meta: {
      pageTitle: 'Instellingen',
      breadcrumb: [
        {
          text: 'instellingen',
        },
        {
          text: 'account',
          active: true,
        },
      ],
      action: 'read',
      resource: 'account',
    },
  },
  {
    path: '/users/list',
    name: 'app-users-list',
    component: () => import('@/views/pages/user/users-list/UsersList.vue'),
    meta: {
      pageTitle: 'Gebruikers',
      breadcrumb: [
        {
          text: 'Gebruikers',
        },
        {
          text: 'overzicht',
          active: true,
        },
      ],
      action: 'read',
      resource: 'users',
    },
  },
  {
    path: '/users/edit/:id',
    name: 'app-users-edit',
    component: () => import('@/views/pages/user/users-edit/UsersEdit.vue'),
    meta: {
      pageTitle: 'Gebruikers',
      breadcrumb: [
        {
          text: 'Gebruiker',
        },
        {
          text: 'aanpassen',
          active: true,
        },
      ],
      action: 'read',
      resource: 'users',
    },
  },
  {
    path: '/settings/apps',
    name: 'app-apps',
    component: () => import('@/views/pages/apps/Apps.vue'),
    meta: {
      pageTitle: 'Apps',
      breadcrumb: [
        {
          text: 'Mijn apps',
          active: true,
        },
      ],
      action: 'read',
      resource: 'apps',
    },
  },
]
